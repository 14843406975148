<template>
  <div class="md:tw-mt-24 tw-mt-8">

    <div v-for="(option, i) in options " :key="i" :class="questionType==='single_answer'&& selected===option || questionType==='multiple_answer'&&selected.includes(option)?'selected-option':'option-card'"
         class="tw-p-2.5 tw-flex"
         @click="toggleSelected(questionType,option,i)">
      <v-avatar class="option" color="#C5E7FF" size="24">{{ optionsLabels[i] }}</v-avatar>
      <span>{{ option }}</span>
    </div>
    <div class="tw-mt-10">
      <BaseButton :block="true" :button-text="questions.length===counter?'Submit':'Next'" :disabled="selected=== ''"
                  :loading="btn_loading" @click="nextQuestion()"></BaseButton>
    </div>
  </div>
</template>

<script>
import BaseButton from "@/components/reusables/BaseButton";

export default {
  name: "JobAssessmentOptionsComponent",
  components: {BaseButton},
  props: {
    options: [Array],
    questionId: [Number, String],
    questionType: [String],
    btn_loading: [Boolean],
    selectedOptions: [Array, Object],
    counter: [Number, String],
    questions: [Array],
  },
  data() {
    return {
      selected: [],
      optionsLabels: ["A", "B", "C", "D", "E", "F", "G"],
      test_type: this.$route.query.category.split(' ')[0].toLowerCase()


    }
  },
  computed: {
    getLoginUser() {
      return JSON.parse(sessionStorage.getItem('userData')) || null;
    }
  },
  methods: {
    toggleSelected(type, option, index) {
      // check if they are coming from another app, the submission data is different
      console.log(index)
      if (this.$route.query.from) {
        if (type === 'multiple_answer') {
          if (this.selected.includes(option)) {

            let selectedIndex = this.selected.indexOf(option)
            if (selectedIndex !== -1) {
              this.selected.splice(selectedIndex, 1)
            }
          } else {
            this.selected.push(option)
            // this.selected = option

          }


        } else {
          this.selected = option


        }
      } else {
        if (type === 'multiple_answer') {
          if (this.selected.includes(option)) {

            let selectedIndex = this.selected.indexOf(option)
            if (selectedIndex !== -1) {
              this.selected.splice(selectedIndex, 1)
            }
          } else {
            this.selected.push(option)
          }
        } else {
          this.selected = option
          console.log(this.selected)
        }
      }
    },
    nextQuestion() {
      this.$emit('select', this.questionId, this.selected.toString())
      this.$emit('nextQuestion', {
        job_id: this.$route.query.jobId,
        program_id: this.$route.query.id,
        email: this.getLoginUser.email,
        response: this.selectedOptions,
        test_type: this.$route.query.category.split(' ')[0].toLowerCase()

      })
    }
  }
}
</script>

<style scoped>
.option-card {
  background: #FFFFFF;
  border: 1px solid #D0DCE4;
  box-sizing: border-box;
  box-shadow: 0px 2px 4px rgba(0, 122, 206, 0.08);
  border-radius: 8px;
  margin-bottom: 16px;
}

span {
  font-family: DM Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
  margin-left: 10px;
  color: #475661;

}

.option {
  font-family: DM Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #142E70 !important;

}

.selected-option {
  background: #FFFFFF;
  border: 2px solid #008EEF;
  box-sizing: border-box;
  box-shadow: 0px 2px 4px rgba(0, 122, 206, 0.08);
  border-radius: 8px;
  margin-bottom: 16px;
  transition: ease;

}
</style>
