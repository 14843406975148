<template>
  <div class="md:tw-mt-24 tw-mt-8">
    <div>
      <div v-for="(question,item) in question_content.questions" :key="item" v-show="comprehensionCounter===item">
        <h2 v-html="question.content"></h2>
        <div v-for="(option, i) in question.options"   @click="toggleSelected(questionType,option)"  :key="i"  class="tw-p-2.5 tw-flex" :class="selected===option || selected.includes(option)?'selected-option':'option-card'">
          <v-avatar class="option" size="24" color="#C5E7FF">{{optionsLabels[i]}}</v-avatar>
          <span>{{option}}</span>
        </div>
        <div class="tw-mt-10">
          <BaseButton :loading="btn_loading" @click="nextQuestionForPsychometric(question.id)" :block="true" :button-text="questions.length===counter?'Submit':'Next'" :disabled="selected=== ''" ></BaseButton>
        </div>
      </div>
    </div>

  </div>

</template>

<script>
import BaseButton from "@/components/reusables/BaseButton";
export default {
name: "PsychometricOptionsType",
  components: {BaseButton},
  props: {
    options:[Array],
    questionId:[Number,String],
    questionType:[String],
    btn_loading:[Boolean],
    selectedOptions:[Array,Object],
    counter:[Number,String],
    questions:[Array],
    question_content:[Object]
  },
  data(){
    return{
      selected:[],
      optionsLabels: ["A", "B", "C", "D", "E", "F", "G"],
      test_type:this.$route.query.category.split(' ')[0].toLowerCase(),
      comprehensionCounter:0,


    }
  },
  computed:{
    getLoginUser() {
      return JSON.parse(sessionStorage.getItem('userData')) || null;
    }
  },
  methods:{
    toggleSelected(type,option){
      if(type === 'multiple_answer') {
        this.selected.push(option)
        console.log(this.selected.toString())

      }
      else{
        this.selected = option
        console.log(this.selected)


      }
    },
    nextQuestionForPsychometric(id) {
      if (this.comprehensionCounter < this.question_content.questions.length - 1) {
        this.comprehensionCounter++
        this.$emit('select', id, this.selected.toString())

      } else {
        this.$emit('select', id, this.selected.toString())
        this.$emit('nextQuestion', {
          job_id: this.$route.query.jobId,
          email: this.getLoginUser.email,
          response: this.selectedOptions,
          test_type: this.$route.query.category.split(' ')[0].toLowerCase()

        })
      }
    },


  }
}
</script>

<style scoped>
.option-card{
  background: #FFFFFF;
  border: 1px solid #D0DCE4;
  box-sizing: border-box;
  box-shadow: 0px 2px 4px rgba(0, 122, 206, 0.08);
  border-radius: 8px;
  margin-bottom: 16px;
}
span {
  font-family: DM Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
  margin-left: 10px;
  color: #475661;

}
.option{
  font-family: DM Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #142E70 !important;

}
.selected-option{
  background: #FFFFFF;
  border: 2px solid #008EEF;
  box-sizing: border-box;
  box-shadow: 0px 2px 4px rgba(0, 122, 206, 0.08);
  border-radius: 8px;
  margin-bottom: 16px;
  transition: ease;

}

</style>
