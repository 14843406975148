<template>
<div>
  <v-row v-for="(data,i) in questions" :key="i" class="tw-items-dcenter  " v-show="counter===i">
    <v-col  cols="12" md="6" sm="12">
      <JobQuestionComponent :question-length="questions.length" :count="counter+1" :question="data"/>
    </v-col>
    <v-col cols="12" md="1" sm="0" class="tw-hidden md:tw-block">
    </v-col>
    <v-col cols="12" md="5" sm="12" v-if="data.is_comprehension">
      <PsychometricOptionsType :question_content="data" :selected-options="selectedOptions" :btn_loading="btn_loading" :questions="questions" :counter="counter" :question-type="data.question_type" :question-id="data.id" @select="getSelectedOption" @nextQuestion="nextQuestion" :options="data.options"/>
    </v-col>
    <v-col v-else cols="12" md="5" sm="12">
      <JobAssessmentOptionsComponent :selected-options="selectedOptions" :btn_loading="btn_loading" :questions="questions" :counter="counter" :question-type="data.question_type" :question-id="data.id" @select="getSelectedOption" @nextQuestion="nextQuestion" :options="data.options"/>
    </v-col>
  </v-row>

</div>
</template>

<script>
import JobQuestionComponent from "@/components/dashboard/jobAssessment/JobQuestionComponent";
import PsychometricOptionsType from "@/components/dashboard/jobAssessment/PsychometricOptionsType";
import JobAssessmentOptionsComponent from "@/components/dashboard/jobAssessment/SkillTestOptionsComponent";
export default {
name: "PsychometricTestTypeWrapper",
  components: {JobAssessmentOptionsComponent, PsychometricOptionsType, JobQuestionComponent},
  props: {
      questions:[Array],
    counter:[Number],
    selectedOptions:[Object,Array],
    btn_loading:[Boolean],

  },
  methods: {
    getSelectedOption(id,value){
      this.$emit('select',id,value)
    },
    nextQuestion(data){
      this.$emit('nextQuestion',data)
    }
  }
}
</script>

<style scoped>

</style>
