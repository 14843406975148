// import {getAssessmentQuestionByCategory} from "@/services/api/APIService";

export default{
    methods:{
        shuffleQuestion(questions){
            if(questions.length) {
                // this is shuffling questions gotten from the backend
                for (let i = questions.length - 1; i > 0; i--) {
                    let j = Math.floor(Math.random() * (i + 1)); // random index from 0 to i
                    //"destructuring
                    [questions[i], questions[j]] = [questions[j], questions[i]];

                }
                return questions;
            }
        },
        // getQuestions(questions) {
        //     // this.loading = true
        //     let category = this.$route.query.category.split(' ')[0].toLowerCase()
        //     getAssessmentQuestionByCategory(this.$route.query.jobId, category)
        //         .then(res=>{
        //             console.log(res.data)
        //             questions = res.data
        //             this.shuffleQuestion(questions)
        //             // this.loading = false
        //             // this.startTimer()
        //
        //         })
        //         .catch(err=> {
        //             console.log(err)
        //             this.loading = false
        //
        //         })
        //     return questions
        // },

    }
}
