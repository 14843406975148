<template>
  <div>
    <OnboardingHeader/>
    <div class="tw-pt-36 wrapper md:tw-w-8/12 tw-px-6	tw-m-auto">
      <div v-if="loading">
        <Loader/>
      </div>
      <div v-else>
      <div class="warn tw-mb-6">
        <span>Please do not reload/switch this tab or leave this screen, because your test will be submitted automatically.</span>
      </div>
      <div class="  tw-items-center tw-flex">
          <v-progress-linear color="#008eef" :value="getQuestionProgress" />
          <span class="timer" v-if="+$route.query.time" >{{formattedTimeLeft}}</span>
      </div>
      <div class="scronll tw-mb-16">
        <component :questions="questions" :counter="counter"
                   :selectedOptions="selectedOptions"
                   :btn_loading="btn_loading"
                   @select="getSelectedOption"
                   @nextQuestion="nextQuestion"
                   v-bind:is="getAssessmentTypeToDisplay()"
        />
      </div>
      </div>

    </div>
  </div>
</template>

<script>
import OnboardingHeader from "@/components/reusables/OnboardingHeader";
import assessmentFunctions from "@/mixins/assessment.mixin"
import SkillTestTypeWrapper from "@/components/dashboard/jobAssessment/SkillTestTypeWrapper";
import PersonalityType from "@/components/dashboard/jobAssessment/PersonalityTestTypeWrapper";
import PsychometricTestType from "@/components/dashboard/jobAssessment/PsychometricTestTypeWrapper";

import {
  checkIfApplicantHasStartedProgramTest,
  checkIfApplicantHasStartedTest,
  getAssessmentQuestionByCategory, getEnumVettedQuestions,
  getProgramAssessmentQuestionByCategory, sendVetQuestions,
  startAssessmentByCategory,
  startProgramAssessmentByCategory,
  submitApplicantAssessmentResponse,
  submitApplicantProgramAssessmentResponse
} from "@/services/api/APIService";
import moment from "moment";
import Loader from "@/Loader";
import _ from 'lodash'


export default {
  name: "AssessmentSkillQuestionView",
  components: {Loader,  OnboardingHeader,SkillTestTypeWrapper,PersonalityType,PsychometricTestType },
  mixins:[assessmentFunctions],
  data() {
      return{
        questions:[],
        warnBeforeReload: false,
        counter:0,
        timeLimit: 10,
        timePassed: 0,
        timerInterval: null,
        selectedOptions:{},
        loading:false,
        btn_loading :false,
      }
  },
  created() {
    this.checkIfTestHasBefore()
    const userData =  JSON.parse(sessionStorage.getItem('userData'));
    if(!userData){
      window.location.href = `${process.env.VUE_APP_FRONTEND_AUTH_URL}/auth/login?site=individual&redirect=test&skill=${this.$route.query.skill}&category=${this.$route.query.category}&link_token=${this.$route.query.link_token}`
    }


  },
  watch:{
    timeLeft(newValue) {
      if (newValue === 0) {
        this.onTimeUp();

      }
    }

  },
  computed:{
    timeLeft() {
        return (+this.$route.query.time)*60 - this.timePassed;
    },
    getQuestionProgress(){
      return (this.counter +1)/this.questions.length*100
    },
    formattedTimeLeft() {
      if((+this.$route.query.time)< 60){
        return moment.utc(this.timeLeft*1000).format("mm:ss")
      }
      else{
        return moment.utc(this.timeLeft*1000).format("HH:mm:ss")

      }
    },
    getLoginUser(){
      return JSON.parse(sessionStorage.getItem('userData')) || null;
    },
  },


  methods: {

    getAssessmentTypeToDisplay(){
      let category = this.$route.query.category.split(' ')[0].toLowerCase()
      if(category === 'personality'){
        return PersonalityType
      }
      else if (category === 'skill'){
        return SkillTestTypeWrapper
      }
      else{
        return PsychometricTestType
      }
    },
      nextQuestion(data){
        if(this.counter< this.questions.length-1){
          if (!this.$route.query.skill) this.initiateStartAssessment()
          this.counter++
        }
        else{
          this.submitResponse(data)

        }
      },
    getShuffleQuestion(questions){
        // this is shuffling questions gotten from the backend
       this.shuffleQuestion(questions)
      },
    initiateStartAssessment() {
      if (this.counter === 0) {
        this.btn_loading = true
        let category = this.$route.query.category.split(' ')[0].toLowerCase()
        let check_api;
        // check if they are taking program assessment or job assessment
        if(this.$route.query.from){
          check_api = startProgramAssessmentByCategory({
            category: category,
            program_id:this.$route.query.id,
          })
        }
        else {
          check_api = startAssessmentByCategory(this.getLoginUser.email, this.$route.query.jobId, {
            category: category,
            status: "in_progress"
          })

        }
        //notify backend that applicant has started the test, this is called after the first question is answered
        check_api
            .then((res) => {
              console.log(res)
            })
            .catch(err => {
              console.log(err)
            })
            .finally(() => this.btn_loading = false)
      }
    },
    checkIfTestHasBefore(){
        this.loading = true
      let category = this.$route.query.category.split(' ')[0].toLowerCase()
      let check_api;
        // check if they are taking program assessment or job assessment
        if(this.$route.query.from){
          check_api = checkIfApplicantHasStartedProgramTest(this.$route.query.id,category)
        }else if (this.$route.query.skill){
          return this.getQuestions()
        }
        else {
          check_api = checkIfApplicantHasStartedTest(this.$route.query.jobId,this.getLoginUser.email,category)

        }
      check_api
      .then(res=>{
        // if applicant has started the start before
        if(res.data.is_started){
          // application has selected options in the local storage
          let selectedOptionsFromStorage = JSON.parse(localStorage.getItem('selected'))
          if(selectedOptionsFromStorage){
            this.submitResponse(selectedOptionsFromStorage);
          }
          else{
            // push them to applied, if they have started before and have no data in local storage
            // this.$router.push({name:'AppliedJobs'})
            this.goBack()
          }

        }
        else{
          // if they have not started before, get all the questions
          this.getQuestions()

        }
        console.log(res)
      })
      .catch(err=>{
        console.log(err)
      })
      // .finally(() => this.loading = false)
    },
    getQuestions(){
      this.loading = true
      let category = this.$route.query.category.split(' ')[0].toLowerCase()
      let check_api;
      // check if they are taking program assessment or job assessment
      if(this.$route.query.from){
        check_api = getProgramAssessmentQuestionByCategory(this.$route.query.id,category)
      }else if (this.$route.query.skill){
        check_api = getEnumVettedQuestions(this.$route.query.skill, this.$route.query.link_token)
      }
      else {
        check_api =  getAssessmentQuestionByCategory(this.$route.query.jobId, category)
      }
      check_api
      .then(res=>{
        console.log(res.data)
        this.questions = res.data
        this.getShuffleQuestion(this.questions)
        this.loading = false
        this.startTimer()

      })
      .catch(err=> {
        console.log(err)
        this.loading = false

      })
    },
    startTimer() {
      this.timerInterval = setInterval(() => (this.timePassed += 1), 1000);
    },
    onTimeUp() {
      clearInterval(this.timerInterval);
      this.submitResponse({
        job_id: this.$route.query.jobId,
        email: this.getLoginUser.email,
        program_id: this.$route.query.id,
        response: this.selectedOptions,
        test_type:this.$route.query.category.split(' ')[0].toLowerCase()

      })

    },
    getSelectedOption(id,value){

      // get user option has they choose from the option
      if(this.$route.query.from){
        this.selectedOptions[id] = _.isArray(value) ? value : [value]

      }else if(this.$route.query.skill){
        Object.assign(this.selectedOptions,{[id]:[value]})
      }
      else{
        Object.assign(this.selectedOptions,{[id]:value})
      }
      console.log(this.selectedOptions)
      localStorage.setItem('selected',JSON.stringify({
        job_id: this.$route.query.jobId,
        program_id: this.$route.query.id,
        email: this.getLoginUser.email,
        response: {...this.selectedOptions},
        test_type:this.$route.query.category.split(' ')[0].toLowerCase()

      }))
    },
    submitResponse(data){
      this.btn_loading = true
      let check_api;
      // check if they are taking program assessment or job assessment
      if(this.$route.query.from){
        check_api = submitApplicantProgramAssessmentResponse({
          program_id: this.$route.query.id,
          category: data.test_type,
          response: data.response
        })
      } else if (this.$route.query.skill){
        let data = {
          question_area : this.$route.query.skill,
          response: this.selectedOptions,
          link_token: this.$route.query.link_token
        }
        check_api = sendVetQuestions(data)
      }
      else {
        check_api =  submitApplicantAssessmentResponse({
          job_id:data.job_id,
          email:data.email,
          response: data.response,
          test_type:data.test_type
        })

      }
      check_api
      .then(res=>{
        console.log(res)
        // after they submit remove the option from the storage
        localStorage.removeItem('selected')
        if (this.$route.query.skill){
          this.$router.push({name:'ApplicantDashboard'})
          this.$displaySnackbar({message: 'Submitted', success: true})
        }else {
          this.$router.push({name:'AssessmentFeedbackView',query:this.$route.query})
        }
      })
      .catch(err=>{
        console.log(err.response)
        if(err.response.data.detail=== "You already submitted this test" || err.response.data.detail=== 'You have already taken this test.'){
          localStorage.removeItem('selected')
          this.goBack()

        }
      })
      .finally(() => this.btn_loading = false)
    },
    goBack(){
      let token = sessionStorage.getItem('userToken')
      if(this.$route.query.from){
        window.location.href =
            `${process.env.VUE_APP_LEARNER_FRONTEND_URL}/redirect?token=${token}
        &from=${this.$route.name}&to=${this.$route.query.from}&id=${this.$route.query.id}&program_title=${this.$route.query.programName}`
      }
      else {
        this.$router.push({name:'AppliedJobs'})

      }
    }

  }
}
</script>

<style scoped>
.wrapper{
  /*max-height: 95vh;*/
  overflow: hidden;
}
.timer{
  font-family: DM Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  margin-left: 10px;
  letter-spacing: 0.1em;
}
.scroll {
  max-height: 95vh;
  overflow-y: scroll;
  overflow-x: hidden;

}

.scroll::-webkit-scrollbar {
  background: transparent !important;
  width: 5px;
  height: 8px;
}

.scroll::-webkit-scrollbar-thumb {
  background: #c4c4c4 !important;
  max-height: 20px !important;
  border-radius: 6px;
}
.warn{
  font-family: DM Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 140%;
  /* identical to box height, or 17px */
  padding: 8px 8px 8px 16px;
  letter-spacing: 0.01em;
  color: #FF2E2E;
  background: #FFF8F8;
  border-radius: 8px;

}


</style>
